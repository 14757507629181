<template>
  <div class="IPRecords">
    <h1>{{ $t('views.dnsvs.ip_records.records_to_ip') }} <code>{{ $route.params.ip }}</code></h1>
    <FlatRecordTable :full_edit_fqdn_reducer="full_edit_fqdn_reducer" :create_record_reducer="create_record_reducer"
                     :records="records" :fqdns="fqdns" :ip="$route.params.ip" :subnets="subnets"></FlatRecordTable>
  </div>
</template>

<script>
import FlatRecordTable from '@/components/FlatRecordTable.vue'
import FQDNRRService from '@/api-services/dns_fqdn.service'
import apiutil from '@/util/apiutil'

export default {
  name: 'IPRecords',
  data() {
    return {
      records: null,
      fqdns: null,
      subnets: null,
      full_edit_fqdn_reducer: null,
      create_record_reducer: null
    }
  },
  methods: {
    async fetchData() {
      this.records = null
      const response = await FQDNRRService.getRecordsByIP(this.$store.state, this.$route.params.ip)
      this.records = response.data.record_list
      this.subnets = response.data.ip_subnet_list
      const fqdnSelections = {}
      for (const t of response.data.fqdn_type_list) {
        let disp_name = t.description
        disp_name += ' ['
        disp_name += t.is_nonterminal ? this.$t('system.non_terminal') : this.$t('system.terminal')
        disp_name += ']'
        fqdnSelections[t.name] = { display_name: disp_name }
      }
      this.full_edit_fqdn_reducer = {
        type: fqdnSelections
      }
      const selections = {}
      for (const t of response.data.record_inttype_list) {
        selections[t.record_type] = { display_name: t.record_type }
      }
      const record_fqdn_type_reducer = Object.assign({ '': { display_name: this.$t('system.automatic_handling') } }, this.full_edit_fqdn_reducer.type)
      this.create_record_reducer = {}
      this.create_record_reducer = {
        fqdn: false,
        type: selections,
        fqdn_type: record_fqdn_type_reducer
      }
      this.fqdns = apiutil.dict_by_value_of_array(response.data.fqdn_list, 'value')
    }
  },
  components: {
    FlatRecordTable
  },
  watch: {
    $route() {
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style scoped>

</style>
